import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import classNames from 'classnames';
import type React from 'react';

type ClickableDropdownProps = {
  disclosure: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  position?: 'left' | 'right';
  top?: string;
};
export default function ClickableDropdown({
  disclosure,
  children,
  className,
  position,
  top = 'top-7',
}: ClickableDropdownProps) {
  return (
    <Popover className="relative">
      <PopoverButton className="focus:outline-none">{disclosure}</PopoverButton>
      <PopoverPanel
        className={classNames(
          className,
          `absolute z-10 bg-white ${top} rounded-md shadow-md border border-gray-200 overflow-hidden`,
          position === 'left' ? 'right-0' : 'left-0',
        )}
      >
        {children}
      </PopoverPanel>
    </Popover>
  );
}
