import { useTranslations } from 'next-intl';

import Icon from './Icon';

interface SearchBarProps {
  className?: string;
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
}

export default function SearchBar({
  className,
  placeholder,
  value,
  onChange,
}: SearchBarProps) {
  const t = useTranslations();
  return (
    <div className={`relative block text-gray-600 ${className}`}>
      <button type="submit" className="absolute top-1.5 left-2">
        <Icon
          icon={{
            source: 'local',
            name: 'search',
            type: 'line',
          }}
          height={20}
          width={20}
        />
      </button>
      <input
        className="h-8 w-full rounded-md border border-gray-100 bg-gray-50 px-2 pl-8 text-sm focus:border-gray-200 focus:ring-0"
        type="search"
        name="search"
        placeholder={placeholder ?? t('SearchBar.search')}
        autoComplete="off"
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
      />
    </div>
  );
}
