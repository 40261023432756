import type { Table } from '@tanstack/react-table';
import { Button } from 'app/components/Button';
import Icon from 'app/components/Icon';
import { Analytics } from 'app/lib/analytics';
import { useTranslations } from 'next-intl';
import { useCallback, useMemo, useState } from 'react';

type RawCellValue =
  | string
  | number
  | boolean
  | null
  | undefined
  | string[]
  | number[];

type CsvCellValue = string | number;

interface ExportTableButtonProps<T> {
  table: Table<T>;
  serverSideRowPagesIterator?: (
    pageSize: number,
  ) => AsyncGenerator<T[], void, unknown>;
}

export default function ExportTableButton<T>({
  table,
  serverSideRowPagesIterator,
}: ExportTableButtonProps<T>) {
  const [loading, setLoading] = useState(false);
  const t = useTranslations();
  const accessorColumns = table
    .getAllFlatColumns()
    .filter((column) => typeof column.accessorFn !== 'undefined');

  const rowsIterator = useMemo(() => {
    if (serverSideRowPagesIterator) {
      return async function* iterateOverRemoteRows() {
        for await (const rowPage of serverSideRowPagesIterator(100)) {
          for (const row of rowPage) {
            // bit of a hack here: we're creating a fake row object to get
            // the printed row values for rows that haven't been rendered in the table.
            // this is necessary because in server-side paginated tables, react-table
            // only sees the current page of rows at a given time.
            yield {
              getValue: (columnId: string) =>
                accessorColumns
                  .find((column) => column.id === columnId)
                  ?.accessorFn?.(row, 0) as RawCellValue,
            };
          }
        }
      };
    }

    // this function doesn't actually need to be async, but we make it to be consistent with the server-side case, easier to handle later
    return async function* iterateOverLocalRows() {
      for (const row of table.getPrePaginationRowModel().flatRows) {
        yield row;
      }
    };
  }, [
    table.getPrePaginationRowModel,
    serverSideRowPagesIterator,
    accessorColumns,
  ]);

  const exportTable = useCallback(async () => {
    Analytics.capture('exported_table', {
      url: window.location.pathname,
    });
    setLoading(true);
    let csv = '';

    // Print headers
    csv += `${accessorColumns
      .map((column) => {
        let headerString = '';
        if (
          column.parent?.columnDef.header &&
          typeof column.parent.columnDef.header === 'string'
        ) {
          // Print column group headers
          headerString += column.parent.columnDef.header += ' - ';
        }

        if (typeof column.columnDef.header === 'string') {
          headerString += column.columnDef.header;
        } else {
          headerString += column.id;
        }

        return convertCellToCsvValue(headerString);
      })
      .join(',')}\n`;

    // Print rows
    for await (const row of rowsIterator()) {
      const rowCells: CsvCellValue[] = [];
      for (const columnId of accessorColumns.map((column) => column.id)) {
        rowCells.push(convertCellToCsvValue(row.getValue(columnId)));
      }
      csv += `${rowCells.join(',')}\n`;
    }

    const csvFile = new File(
      [csv],
      `carbonfact-${window.location.pathname.replaceAll('/', '-')}-${new Date().toLocaleDateString().replaceAll('/', '-')}.csv`,
      { type: 'text/csv', endings: 'native' },
    );

    const csvUrl = URL.createObjectURL(csvFile);

    // Just using window.open won't conserve the file name,
    // go through a hidden link instead.
    const link = document.createElement('a');
    link.href = csvUrl;
    link.download = csvFile.name;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();

    setLoading(false);

    // Clean up once download has started
    setTimeout(() => {
      document.body.removeChild(link);
      URL.revokeObjectURL(csvUrl);
    }, 1000);
  }, [accessorColumns, rowsIterator]);

  return (
    <Button.Default
      variant="secondary"
      onClick={() => void exportTable()}
      loading={loading}
    >
      <Icon
        icon={{
          source: 'hero',
          name: 'ArrowDownTrayIcon',
          type: 'solid',
        }}
      />
      {t('ExportTableButton.export')}
    </Button.Default>
  );
}

function convertCellToCsvValue(cell: RawCellValue): CsvCellValue {
  if (typeof cell === 'undefined') {
    return '';
  }
  if (cell === null) {
    return 'null';
  }
  if (typeof cell === 'string') {
    return `"${cell.replace(/"/g, '"')}"`;
  }
  if (typeof cell === 'boolean') {
    return cell ? 'TRUE' : 'FALSE';
  }
  if (Array.isArray(cell)) {
    return `"${cell.join(', ')}"`;
  }
  if (typeof cell === 'number') {
    // Limit number precision so that excel imports it as a number
    return Number(cell.toFixed(14));
  }

  return cell;
}
